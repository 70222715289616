/* You can add global styles to this file, and also import other style files */
.display-group, .input-group {
    width: 50%;
    min-width: 500px;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    margin-bottom: 14px;
}

label {
    flex: 1 100%;
    font-weight: 600;
    margin-bottom: 3px;
    margin-right: 1em;
}

input {
    flex: 1;
}